import React, { Component } from "react";
import {
  Alert,
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  TouchableHighlight,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { Table, Row } from 'react-native-table-component';
import { connect } from "react-redux";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { submitForm, getForm, beginSubmit, checkRecordStatus, showStatusError, checkLogStatus, showLogStatusError } from "../actions/menu";
import { sendSubmit, setBigFileIsLoading, loadingIssue } from "../actions/submissions";
import { setStatusChange, unsetStatusChange } from "../actions/records";
import { setIsLogGroup, getGroupDetail, setLogGroupsSubAction } from "../actions/loggroups";
import moment from "moment-timezone";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FormComponent from "./component";
import uuid from "uuid";
import LogDialog from "./dialogs/log";
import Section from "./components/section";
import RecordActions from "./widgets/recordActions";
import colors from "../constants/colors";
import alerts from "./components/alert"
import StorageService from "../services/storage";
import PaymentInfo from './dialogs/paymentInfo';
import Swal from "sweetalert2";
import "./styles.css";
import { viewContainer } from "./functions";
import NewIssue from "./dialogs/newIssue";
import { editDefaultData } from "../actions/menu";

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submission: {},
      lastStatus: false,
      checkboxkey: 0, // increment by 1 each time yesnoall is clicked
      components: [],
      choicelist: {},
      uuid: "",
      derived: false,
      autosave: false,
      tmpIssues: [],
      showPaymentInfo: false,
      callOnSave: false,
      lotConsecutive: 1,
      formLoaded: false,
      width: window.innerWidth,
      rerenderTable: false,
      openTableIssue: false,
      compIssueId: null,
      issueLabel: '',
      issuePosition: null,
      issueWidth: "small"
    };
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate() {
  }

  componentDidMount() {
    this.props.getForm(this.props.submissionSelected?._id, () => {
      this.checkLotSubmissions();
      this.setState({ formLoaded: true });
    });
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  checkLotSubmissions() {
    const { submissionSelected } = this.props;
    let num_array = [];
    if (submissionSelected) {
      let sub_id = submissionSelected._id.slice(-8);
      for (let component of submissionSelected.components) {
        let obj = submissionSelected.submission[component.id];
        if (obj) {
          obj = obj.toString();
          if (component.type === "lotcode" || component.type === "customlotcode") {
            if (obj.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
          if (component.type === "inventory") {
            if (obj.lotcode && obj.lotcode.includes(sub_id)) {
              obj = parseInt(obj.replace(sub_id, ""));
              num_array.push(obj);
            }
          }
        }
      }
      var max = Math.max(...num_array);
      var min = Math.min(...num_array);
      if (max && max > 0) {
        this.setState({ lotConsecutive: max + 1 })
      }
    }
  }

  setCompleteStatus() {
    const { submission } = this.state;
    if (submission.status) {
      this.setState({ lastStatus: submission.status })
    }
    submission.status = "complete";
    this.setState({ submission: submission }, () => {
      this.submitForm("complete");
    });
  }

  saveForm = () => {
    this.setState(
      {
        autosave: true,
      },
      () => {
        this.props.autoSave(
          this.state.components,
          this.state.choicelist,
          this.state.submission,
          this.props.newReport ? uuid.v1() : this.state.uuid,
          this.props.task_id,
          this.state.autosave
        );
      }
    );
  };

  getComponentById(id) {
    const { components } = this.state;
    var r = null;
    for (var i = 0; i < components.length; i++) {
      if (components[i]["id"] == id) return components[i];
    }
  }

  onComponentChange(id, value, subtype = false, position) {
    const { submission, components, lotConsecutive } = this.state;
    // let newSubmission = Object.assign({}, submission);
    let newSubmission = JSON.parse(JSON.stringify(submission));
    let checkboxkey = this.state.checkboxkey;
    var c = this.getComponentById(id);
    if (typeof c != "undefined") {
      if (subtype) {
        if (typeof newSubmission[c.id] != "undefined") {
          // newSubmission[c.id][subtype] = value;
          if (newSubmission[c.id] == "") {
            newSubmission[c.id] = [];
          }
          if (position || position == 0) {
            if (newSubmission[c.id][position])
              newSubmission[c.id][position][subtype] = value;
            else {
              newSubmission[c.id][position] = {};
              newSubmission[c.id][position][subtype] = value;
            }
          } else {
            newSubmission[c.id][subtype] = value;
          }
        } else {
          newSubmission[c.id] = {};
          if (position || position == 0) {
            if (newSubmission[c.id][position])
              newSubmission[c.id][position][subtype] = value;
            else {
              newSubmission[c.id][position] = {};
              newSubmission[c.id][position][subtype] = value;
            }
          } else {
            newSubmission[c.id][subtype] = value;
          }
          // newSubmission[c.id][subtype] = value;
        }
        if (subtype === "lotcode") {
          this.setState({ lotConsecutive: lotConsecutive + 1 })
        }
      } else {
        if (position || position == 0) {
          newSubmission[c.id][position] = value;
        } else {
          newSubmission[c.id] = value;
        }
      }
      // YesNoAll logic
      if (c.type === "yesnoall") {
        var section = components[id].section_id;
        components.forEach((c) => {
          if (c.type === "yesno" && c.section_id === section) {
            if (position || position == 0) {
              newSubmission[c.id][position] = value;
            } else {
              newSubmission[c.id] = value;
            }
          }
        });
        // force yesno components to remount
        checkboxkey = checkboxkey + 1;
      }
      if (c.type === "lotcode" || c.type === "customlotcode") {
        // alert(lotConsecutive)
        this.setState({ lotConsecutive: lotConsecutive + 1 })
      }
    } else {
      if (subtype) {
        newSubmission[id] = {};
        if (position || position == 0) {
          newSubmission[id][position][subtype] = value;
        } else {
          newSubmission[id][position] = value;
        }
      } else {
        if (position || position == 0) {
          newSubmission[id][position] = value;
        } else {
          newSubmission[id] = value;
        }
      }
      // newSubmission[id] = value;
    }

    this.setState({ submission: newSubmission, checkboxkey: checkboxkey });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { companyTimezone } = this.props;
    var submission = {};
    var components = [];
    var choicelist = {};
    var uuid = "";

    var issues = newProps.submissionSelected?.issues
    if (issues) {
      issues = Object.values(issues)
      if (issues.length >= 0) {
        this.setState({ tmpIssues: issues }, this.forceUpdate.bind(this));
      }
    }
    // Initialize right after the form is loaded from network/memory
    if (!newProps.isLoaded || !this.props.isLoading) {
      return;
    }

    // Load old submission
    if (!this.props.newReport && !this.props.isSubmission) {
      uuid = this.props.previousSubmissions[this.props.task_id]["uuid"];
      submission = this.props.previousSubmissions[this.props.task_id][
        "submission"
      ];
      components = this.props.previousSubmissions[this.props.task_id][
        "components"
      ];
      choicelist = this.props.previousSubmissions[this.props.task_id][
        "choicelist"
      ];
    }

    if (!this.props.newReport && this.props.isSubmission) {
      uuid = this.props.submissionSelected.uuid;
      submission = this.props.submissionSelected.submission;
      const tmpComponents = this.props.submissionSelected.components;
      choicelist = this.props.submissionSelected.choicelist;
      let indextmpComponents = 0
      tmpComponents.forEach((comp, indx) => {
        let nComponent = { ...comp }
        if (comp.type === "section") {

          newProps.components.forEach(nComp => {
            if (nComp.label === comp.label) {
              if (nComp.attachmentId && typeof nComp.attachmentId !== 'undefined' && typeof nComp.attachmentId !== '' && typeof nComp.attachmentId !== null) {
                nComponent.attachment = nComp.attachment;
                nComponent.attachment_type = nComp.attachment_type
                nComponent.attachmentId = nComp.attachmentId

              }
            }

          });
        }
        components.push(nComponent)
      })
    }

    if ("components" in newProps && "choicelist" in newProps) {
      const date = moment().format("YYYY-MM-DD HH:mm");
      const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
      // New report
      if (this.props.newReport) {
        components = newProps.components;
        choicelist = newProps.choicelist;
        // components.filter(section => section.type == 'section').forEach((c) => {

        // })
        components.forEach((c) => {
          var tableSect = components.filter(sect => c.section_id == sect.section_id && sect.type == "section" && sect?.direction == "table" && c.type != "section");
          
          if(tableSect.length > 0){
            var defaultData = 1;
            var dataType = tableSect[0]?.dataType ? tableSect[0]?.dataType : null;
            if(tableSect[0]?.defaultData > 0){
              defaultData = tableSect[0]?.defaultData;
            }else{
              defaultData = 1;
            }
            defaultData = defaultData - 1;
            var defaultSubmission = tableSect[0]?.defaultSubmission ? tableSect[0]?.defaultSubmission : {};
            for(var comp = 0; comp <= defaultData; comp++){
              if(comp == 0){
                if (c.type === "datetime"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [initialDateTime];
                    }
                  }else{
                    submission[c.id] = [initialDateTime];
                  }
                }else if (
                  c.type === "inputlabel" ||
                  c.type === "input" ||
                  c.type === "textarea" ||
                  c.type === "lotecode" ||
                  c.type === "customlotcode" ||
                  c.type === "picture"
                ){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [""];
                    }
                  }else{
                    submission[c.id] = [""];
                  }
                }else if (c.type === "dropdown"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [choicelist[c.object_type_id][0]["id"]];
                    }
                  }else{
                    submission[c.id] = [choicelist[c.object_type_id][0]["id"]];
                  }
                }else if (c.type === "signature"
                      || c.type === "timer" 
                      || c.type === "timerauto"){
                    if(defaultSubmission[c.id]){
                      if(defaultSubmission[c.id][comp]){
                        submission[c.id] = [defaultSubmission[c.id][comp]];
                      }else if(c.prefilledData){
                        submission[c.id] = [c.prefilledData];
                      }else{
                        submission[c.id] = [0];
                      }
                    }else{
                      submission[c.id] = [0];
                    }  
                }else if (c.type == "yesno" || c.type == "yesnoall"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [0];
                    }
                  }else{
                    submission[c.id] = [0];
                  }
                }
                else if (c.type == "document"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [0];
                    }
                  }else{
                    submission[c.id] = [0];
                  }
                }
                else if (c.type == "chatAI"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [{response: "", question: ""}];
                    }
                  }else{
                    submission[c.id] = [{response: "", question: ""}];
                  }
                }
                else if (c.type == "inventory"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = [defaultSubmission[c.id][comp]];
                    }else if(c.prefilledData){
                      submission[c.id] = [c.prefilledData];
                    }else{
                      submission[c.id] = [{lotcode: "", productname: "", quantity: ""}];
                    }
                  }else{
                    submission[c.id] = [{lotcode: "", productname: "", quantity: ""}];
                  }
                }
              }else{
                var sub = submission[c.id];
                if (c.type === "datetime"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      // submission[c.id] = [defaultSubmission[c.id][comp]];
                      sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      sub.push(c.prefilledData);
                    }else{
                      sub.push(initialDateTime);
                    }
                  }else{
                    sub.push(initialDateTime);

                  }
                } 
                else if (
                  c.type === "inputlabel" ||
                  c.type === "input" ||
                  c.type === "textarea" ||
                  c.type === "lotecode" ||
                  c.type === "customlotcode" ||
                  c.type === "picture"
                ){
                  
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push("");
                    }
                  }else{
                    submission[c.id] = sub.push("");
                  }
                }else if (c.type === "dropdown"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push(choicelist[c.object_type_id][0]["id"]);
                    }
                  }else{
                    submission[c.id] = sub.push(choicelist[c.object_type_id][0]["id"]);
                  }
                }
                else if (c.type === "signature"
                      || c.type === "timer" 
                      || c.type === "timerauto"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push(0);
                    }
                  }else{
                    submission[c.id] = sub.push(0);
                  }
                }
                else if (c.type == "yesno" || c.type == "yesnoall"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push(0);
                    }
                  }else{
                    submission[c.id] = sub.push(0);
                  }
                }else if (c.type == "document"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push(0);
                    }
                  }else{
                    submission[c.id] = sub.push(0);
                  }
                }
                else if (c.type == "chatAI"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push({response: "", question: "" });
                    }
                  }else{
                    submission[c.id] = sub.push({response: "", question: ""});
                  }
                }
                else if (c.type == "inventory"){
                  if(defaultSubmission[c.id]){
                    if(defaultSubmission[c.id][comp]){
                      submission[c.id] = sub.push(defaultSubmission[c.id][comp]);
                    }else if(c.prefilledData){
                      submission[c.id] = sub.push(c.prefilledData);
                    }else{
                      submission[c.id] = sub.push({lotcode: "", productname: "", quantity: ""});
                    }
                  }else{
                    submission[c.id] = sub.push({lotcode: "", productname: "", quantity: ""});
                  }
                }

                  submission[c.id] = sub;
              }
            }
          }else{
            if (c.type === "datetime") submission[c.id] = initialDateTime;
            else if (
              c.type === "inputlabel" ||
              c.type === "input" ||
              c.type === "textarea" ||
              c.type === "lotecode" ||
              c.type === "customlotcode" ||
              c.type === "picture"
            ){
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = "";
              }
            }else if (c.type === "dropdown"){
              
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = choicelist[c.object_type_id][0]["id"];
              }
            }
            else if (c.type === "signature"
                  || c.type === "timer" 
                  || c.type === "timerauto"){
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = 0;
              }
            }else if (c.type == "yesno" || c.type == "yesnoall"){
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = 0;
              }
            }else if (c.type == "document"){
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = 0;
              }
            }
            else if (c.type == "chatAI"){
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = {response: "", question: ""};
              }
            }
            else if (c.type == "inventory"){
              if(c.prefilledData){
                submission[c.id] = c.prefilledData;
              }else{
                submission[c.id] = {lotcode: "", productname: "", quantity: ""};
              }
            }
          }
        });
      }
    }

    this.setState({
      submission,
      components,
      choicelist,
      uuid,
      rerenderTable: !this.state.rerenderTable
    });
  }

  // Do not render when this.state.submissions is modified
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isLoading !== nextProps.isLoading ||
      this.props.isErrorSave !== nextProps.isErrorSave ||
      this.props.isErrorRetrieve !== nextProps.isErrorRetrieve ||
      this.props.isLoaded !== nextProps.isLoaded ||
      this.props.isSaving !== nextProps.isSaving ||
      this.state.lotConsecutive !== nextState.lotConsecutive ||
      this.state.checkboxkey !== nextState.checkboxkey ||
      this.state.rerenderTable != nextState.rerenderTable ||
      this.state.openTableIssue !== nextState.openTableIssue
    );
  }

  submitFormSave(status = "in_progress") {
    this.props.startSubmit(
      this.state.components,
      this.state.choicelist,
      this.state.submission,
      this.props.newReport ? uuid.v1() : this.state.uuid,
      this.props.task_id ? this.props.task_id : this.props.submissionSelected.task_id,
      this.props.isLogGroup ? this.props.logGroupSelected.id : '',
      status
    );
    this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');

  }

  submitForm = async (status) => {
    const { submission, lastStatus } = this.state;
    let cont = 0;
    let labels = [];
    const result = this.state.components.filter(f => f.mandatory === true);

    for (let i = 0; i < result.length; i++) {
      let index = Object.keys(this.state.submission).indexOf(result[i].id);
      let valueMandatory = Object.values(this.state.submission)[index];

      if (valueMandatory != undefined) {
        if (valueMandatory.length > 0 || valueMandatory != 0) {

          if (result[i].type === 'inventory') {
            if (valueMandatory.lotcode && valueMandatory.productname && valueMandatory.quantity) {
              if (valueMandatory.lotcode.length > 0 && valueMandatory.productname.length > 0 && valueMandatory.quantity.length > 0) {
                cont++;
              } else {
                labels.push(result[i].label);
              }
            } else {
              labels.push(result[i].label);
            }

          } else {
            cont++;
          }
        } else {
          labels.push(result[i].label);
        }
      } else {
        labels.push(result[i].label);
      }
    }

    if (cont === result.length) {
      await this.props.unsetStatusChange();
      this.props.startSubmit(
        this.state.components,
        this.state.choicelist,
        this.state.submission,
        this.props.newReport ? uuid.v1() : this.state.uuid,
        this.props.task_id ? this.props.task_id : this.props.submissionSelected.task_id,
        this.props.isLogGroup ? this.props.logGroupSelected.id : '',
        status
      );
      this.props.logGroupSelected.id && this.props.isLogGroup ? this.navigateToGroup() : this.props.navigation.navigate('MainView');

    } else {
      await this.props.setStatusChange();
      submission.status = lastStatus;
      this.setState({ submission: submission })

      Swal.fire({
        title: "Please check your form",
        text: "Complete the required fields before submitting the report " + '(' + labels.toString().replaceAll(",", ", ") + ')',
        confirmButtonText: "Ok",
        backdrop: false
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }
  }


  navigateToGroup() {
    this.props.setLogGroupsSubAction(),
      this.props.navigation.navigate('GroupView');
  }

  renderIssue(issues, component) {
    let issueComment = "";
    let found = false;
    let issueRender = []
    if (Array.isArray(issues)) {
      issues?.map((issue) => {
        if (issue.id == component.id) {
          issueComment = issue.comment;
          found = true;
          issueRender = issue;
        }
      });
    }
    return found ? issueRender : null;

  }

  printAllIssues(issues, component, pos, fromTable) {
    const { companyTimezone, submissionSelected } = this.props;
    const status = submissionSelected ? submissionSelected.status : "new";
    const locIssues = issues.filter(issue => issue?.id == component?.id);
    var position = pos;
    if(fromTable && pos == null){
      position = 0;
    }
    console.log(position)
    return (
      <View style={{ flex: 1 }}>

        {locIssues.map(issue => {
          if (position || position == 0) {
            if (position == issue?.position) {
              return <React.Fragment>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                    {issue.solved ?
                      <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                      <Text style={styles.issueText}>{issue?.comment}</Text>}
                  </TouchableOpacity>
                  {!issue.solved &&
                    <TouchableOpacity 
                      disabled={status == "archived" || status == "approved"}
                      style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                      onPress={() => {
                        const { submissionSelected } = this.props;
                        const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                        const newIssues = [...issues];
                        newIssues[commentIndx] = {
                          ...issue,
                          solved: true
                        }
                        this.props.loadingIssue(component.id);
                        this.setState({rerenderTable: !this.state.rerenderTable})
                        this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => {
                          this.props.loadingIssue(null);
                          this.setState({rerenderTable: !this.state.rerenderTable})
                        });
                      }}
                    >
                      <Text style={styles.issueText}>Solve</Text>
                    </TouchableOpacity>
                  }
                </View>
                {
                  issue.createdBy && issue.createdDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {this.convertUTCDateToLocalDate(cDate).toLocaleString('en-US')}</Text>

                    : null
                }
                {
                  issue.solvedBy && issue.solvedDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {this.convertUTCDateToLocalDate(sDate).toLocaleString('en-US')}</Text>

                    : null
                }
                <View>

                </View>
              </React.Fragment>
            }
            else if(position == 0 && fromTable && !issue?.position){
              return <React.Fragment>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                    {issue.solved ?
                      <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                      <Text style={styles.issueText}>{issue?.comment}</Text>}
                  </TouchableOpacity>
                  {!issue.solved &&
                    <TouchableOpacity 
                      disabled={status == "archived" || status == "approved"}
                      style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                      onPress={() => {
                        const { submissionSelected } = this.props;
                        const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                        const newIssues = [...issues];
                        newIssues[commentIndx] = {
                          ...issue,
                          solved: true
                        }
                        this.props.loadingIssue(component.id);
                        this.setState({rerenderTable: !this.state.rerenderTable})
                        this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => {
                          this.props.loadingIssue(null);
                          this.setState({rerenderTable: !this.state.rerenderTable})
                        });
                      }}
                    >
                      <Text style={styles.issueText}>Solve</Text>
                    </TouchableOpacity>
                  }
                </View>
                {
                  issue.createdBy && issue.createdDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {this.convertUTCDateToLocalDate(cDate).toLocaleString('en-US')}</Text>
                
                    : null
                }
                {
                  issue.solvedBy && issue.solvedDate ?
                    <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>
                    // <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {this.convertUTCDateToLocalDate(sDate).toLocaleString('en-US')}</Text>
                
                    : null
                }
                <View>
              
                </View>
              </React.Fragment>
            }
            return null;
          } else {
            return <React.Fragment>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <TouchableOpacity style={[styles.issueBox, { flex: 7 }]}>
                  {issue.solved ?
                    <Text style={[styles.issueText, { fontStyle: 'italic', textDecorationLine: 'line-through' }]}>{issue?.comment}</Text> :
                    <Text style={styles.issueText}>{issue?.comment}</Text>}
                </TouchableOpacity>
                {!issue.solved &&
                  <TouchableOpacity 
                    disabled={status == "archived" || status == "approved"}
                    style={[styles.issueBox, { flex: 1, marginLeft: 5, backgroundColor: '#fff0a3' }]}
                    onPress={() => {
                      const { submissionSelected } = this.props;
                      const commentIndx = issues.findIndex(elem => elem?.comment == issue?.comment)
                      const newIssues = [...issues];
                      newIssues[commentIndx] = {
                        ...issue,
                        solved: true
                      }
                      this.props.loadingIssue(component.id);
                      this.setState({rerenderTable: !this.state.rerenderTable})
                      this.props.sendSubmit(submissionSelected?._id, newIssues, newIssues[commentIndx].comment, () => {
                        this.props.loadingIssue(null)
                        this.setState({rerenderTable: !this.state.rerenderTable})
                      });
                    }}
                  >
                    <Text style={styles.issueText}>Solve</Text>
                  </TouchableOpacity>
                }
              </View>
              {
                issue.createdBy && issue.createdDate ?
                  <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Created by {issue.createdBy} on {moment(issue.createdDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>

                  : null
              }
              {
                issue.solvedBy && issue.solvedDate ?
                  <Text style={{ fontSize: 16, fontStyle: 'italic' }}>Solved by {issue.solvedBy} on {moment(issue.solvedDate).tz(companyTimezone).format('YYYY-MM-DD')}</Text>

                  : null
              }
              <View>

              </View>
            </React.Fragment>
          }

        }
        )
        }

      </View>
    )
  }

  showSave() {

    const { submissionSelected, formId, taskSelected, isLogGroup } = this.props;
    const { formLoaded } = this.state;
    const status = submissionSelected ? submissionSelected.status : "new";

    if (status != 'archived' && status != 'approved' && status != 'complete')
      return (
        <View style={styles.submitContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              if (this.props.payment_completed) {
                //Validate Status
                var id = taskSelected ? taskSelected?._id : null;
                if (status == "new" && isLogGroup) {
                  this.props.checkLogStatus(id, (statusBD) => {
                    if (!statusBD || statusBD == "pending" || statusBD == "edit_required") {
                      if (formLoaded) this.submitFormSave('in_progress');
                    } else {
                      this.props.showLogStatusError(statusBD);
                      this.props.navigation.navigate('MainView')
                    }
                  })

                } else {
                  if (!id) {
                    id = this.props.newReport ? uuid.v1() : this.state.uuid;
                  }
                  this.props.checkRecordStatus(id, (statusBD) => {
                    // alert("Save" + " " + statusBD + " " +  status + " " +  isLogGroup)
                    if (!statusBD || statusBD == "in_progress" || statusBD == "pending" || statusBD == "edit_required") {
                      if (formLoaded) this.submitFormSave('in_progress');
                    } else {
                      this.props.showStatusError(statusBD);
                      this.props.navigation.navigate('MainView')
                    }
                  })
                }

              } else {
                this.setState({ showPaymentInfo: true, callOnSave: true }, () => { this.forceUpdate() });
              }
            }}
          >
            <Text style={styles.buttonText}>
              Save
            </Text>
          </TouchableOpacity>
        </View>
      );
    else if (status != 'complete')
      return (
        <TouchableOpacity style={styles.readOnlyContainer}>
          <Text style={{ alignSelf: "center" }}>This record is read only</Text>
        </TouchableOpacity>
      );


  }

  saveIssue(issue, issues, submissionId, callback) {
    const newIssues = Array.isArray(issues) ? [...issues, issue] : [issue];
    this.props.sendSubmit(submissionId, newIssues, issue.comment, callback);
  }

  convertUTCDateToLocalDate(date) {

    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString('en-US');
  }
  getTableHeaders(components, section_id) {
    var labels = [];
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      labels.push(component.label);
    })

    return labels;
  }
  getRowComponents(components, section_id, position) {
    var filteredComponents = [];
    const { isLoadingIssue, submissionSelected, companyTimezone, formSubmission } = this.props;
    const { choicelist, checkboxkey, lotConsecutive, submission } = this.state;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    const status = submissionSelected ? submissionSelected.status : "new";
    let issues = submissionSelected ? submissionSelected.issues : null;
    var tableSect = components.filter(sect => section_id == sect.section_id && sect.type == "section" && sect?.direction == "table");
    var defaultSubmission = {};
    var defaultData = null;
    if(tableSect.length > 0){
      defaultSubmission = tableSect[0]?.defaultSubmission ? tableSect[0]?.defaultSubmission : {};
      if(tableSect[0]?.defaultData){
        defaultData = tableSect[0]?.defaultData - 1;
      }else{
        defaultData = 0;
      }
    }

    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      var initial;
      var editable = false;

      let new_label;
      switch (component.type) {
        case "customlotcode":
          new_label = `${component.label} - ${component.lotcodetypename}`;
          break;
        case "inventory":
          new_label = `${component.label} - ${component.inventorytypename} Code`;
          break;
        default:
          new_label = `${component.label}`;
      }
      if(component?.dataType){
        switch(component?.dataType){
          case "default_data":
              if(position <= defaultData){
                editable = true;
              }
            break;
          default:
              editable = false;
            break;
        }
      }

      if(position || position == 0){
        if(submission[component.id]){
          if(typeof submission[component.id] == "object" && submission[component.id].length > 0){
            if(submission[component.id][position] !== undefined){
              initial = submission[component.id][position] 
            }else{
              initial = "";
            }
          }else{
            initial = submission[component.id]
          }
        }else{
          initial = "";
        }
      }else{
        initial = "";
      }
      filteredComponents.push(
        <>
          {
            isLoadingIssue != null && component.id == isLoadingIssue ?
              <ActivityIndicator size={150} color={colors.primary}></ActivityIndicator>
              :
              <View>
                <FormComponent
                  id={component.id}
                  type={component.type}
                  key={component.id}
                  label={component.type == "inventory" ? new_label : ''}
                  placeholder={component.placeholder ? component.placeholder : ""}
                  componentLink={component.componentLink ? component.componentLink : ""}
                  enabledLink={component.enabledLink ? component.enabledLink : ""}
                  initial={initial}
                  onChange={(id, value, subtype, position) => {
                    this.onComponentChange(id, value, subtype, position)
                  }}
                  options={choicelist[component.object_type_id]}
                  checkboxkey={checkboxkey}
                  status={submissionSelected ? submissionSelected.status : false}
                  submissionSelected={submissionSelected ? submissionSelected : null}
                  showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                  lotConsecutive={lotConsecutive}
                  customlot={false}
                  isIssuable={submissionId != null}
                  onSubmitIssue={(issue) => {
                    this.props.loadingIssue(component.id);
                    this.saveIssue({ ...issue, position: position }, issues, submissionId)
                    setTimeout(() => {
                      this.props.loadingIssue(null);
                    }, 1000)
                  }}
                  openIssue={()=>{
                    this.setState({ 
                      openTableIssue: true,
                      compIssueId: component.id,
                      issueLabel: '',
                      issuePosition: position,
                      issueWidth: component?.width ? component?.width : "small",
                      rerenderTable: !this.state.rerenderTable
                    });
                  }}
                  companyTimezone={companyTimezone}
                  position={position}
                  width={component?.width ? component?.width : "small"}
                  defaultComponent={(status == 'archived' || status == 'approved') || formSubmission ? true : editable}
                  closeIssue={()=>{
                    console.log(this.state.rerenderTable)
                    this.setState({rerenderTable: !this.state.rerenderTable});
                  }}
                />
                {issues && this.renderIssue(issues, component) != null ? <View style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,

                }}>
                  {this.printAllIssues(issues, component, position, true)}
                  </View> : null}
              </View>
          }

        </>
      )
    })

    return filteredComponents;
  }

  renderRows(rows, components, section_id) {
    var renderedRows = [];
    for (let i = 0; i <= rows; i++) {
      renderedRows.push(<Row data={this.getRowComponents(components, section_id, i)} widthArr={this.getCellArrayWidth(components, section_id)} style={i % 2 == 0 ? { backgroundColor: '#EDEEEE', justifyContent: "flex-start", zIndex: 'unset' } : { backgroundColor: 'white', justifyContent: "flex-start" }} textStyle={styles.text} />);
    }

    return renderedRows;
  }

  getCellArrayWidth(components, section_id) {
    var widthArray = [];
    var w = 150;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component, idx) => {
      switch (component?.width) {
        case 'small':
          w = 155;
          break;
        case 'medium':
          w = 250;
          break;
        case 'large':
          w = 350;
          break;
        default:
          w = 150;
          break;
      }
      widthArray.push(w);
    })
    return widthArray;
  }

  checkForRows(components, section_id) {
    const { submission } = this.state;
    var rowNumber = null;
    components.filter(component => component.section_id == section_id && component.type != 'section').map((component) => {
      var sub = submission[component.id];
      if (typeof sub === 'object' && sub.length) {
        rowNumber = sub.length;
      }
    })
    if (!rowNumber) {
      rowNumber = 1;
    }
    return rowNumber - 1;
  }
  restTableRow(section_id){
    const { submission, components } = this.state;
    var newSubmission = submission;
    components.filter(component => component.type != 'section' && component.section_id == section_id).map((component) => {
      if(submission[component.id]){
        if(typeof submission[component.id] == "object" && submission[component.id].length > 0){
          newSubmission[component.id].splice(submission[component.id].length - 1, 1);
        }
      }
    });
    this.setState({ submission: newSubmission, rerenderTable: !this.state.rerenderTable })
  }

  addTableRow(section_id, choicelist) {
    const { companyTimezone } = this.props;
    const { submission, components } = this.state;
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
    var newSubmission = submission;
    components.filter(component => component.type != 'section' && component.section_id == section_id).map((component) => {
      var sub = submission[component.id];
      if (typeof sub === 'object' && sub.length > 0) {
        switch(component.type){
          case "datetime":
              if(component.prefilledData){
                newSubmission[component.id].push(component.prefilledData);
              }else{
                newSubmission[component.id].push(initialDateTime);
              }
            break;
          case "dropdown":
            if(component.prefilledData){
              newSubmission[component.id].push(component.prefilledData);
            }else{
              newSubmission[component.id].push(choicelist[component.object_type_id][0]["id"]);
            }
            break;
          case
               "signature",
               "timer",
               "timerauto",
               "document":
                  if(component.prefilledData){
                    newSubmission[component.id].push(component.prefilledData);
                  }else{
                    newSubmission[component.id].push(0);
                  }
            break;
          case "yesno",
               "yesnoall":
                  if(component.prefilledData){
                    newSubmission[component.id].push(component.prefilledData);
                  }else{
                    newSubmission[component.id].push(0);
                  }
            break;
          default:
              if(component.prefilledData){
                newSubmission[component.id].push(component.prefilledData);
              }else{
                newSubmission[component.id].push("");
              }
            break;
        }
      } else {
        var newSub = [sub];
        switch(component.type){
          case "datetime":
              if(component.prefilledData){
                newSub.push(component.prefilledData);
              }else{
                newSub.push(initialDateTime);
              }
            break;
          case "dropdown":
              if(component.prefilledData){
                newSub.push(component.prefilledData);
              }else{
                newSub.push(choicelist[component.object_type_id][0]["id"]);
              }
            break;
            case
                 "signature",
                 "timer",
                 "timerauto",
                 "document":
                if(component.prefilledData){
                  newSub.push(component.prefilledData);
                }else{
                  newSub.push(0);
                }
            break;
          case "yesno",
               "yesnoall":
              if(component.prefilledData){
                newSub.push(component.prefilledData);
              }else{
                newSub.push(0);
              }
            break;
          default:
            if(component.prefilledData){
              newSub.push(component.prefilledData);
            }else{
              newSub.push("");
            }
            break;
        }
        newSubmission[component.id] = newSub;
      }
    })

    this.setState({ submission: newSubmission, rerenderTable: !this.state.rerenderTable })
  }
  editTableRows(defaultData, section_id, defaultSubmission, dataType){
    const {components, submission, choicelist} = this.state;
    const { companyTimezone } = this.props;
    var actualRows = this.checkForRows(components, section_id) + 1;
    var newSub = submission;
    var newComponents = components;
    const date = moment().format("YYYY-MM-DD HH:mm");
    const initialDateTime = moment.tz(date, companyTimezone).tz('UTC').format("YYYY-MM-DD HH:mm");
    newComponents.map((component) => {
      if(component.type == 'section' && component.section_id == section_id){
        component.dataType = dataType;
      }
    })
    console.log(defaultSubmission)
    components.filter(section => section.type != 'section' && section.section_id == section_id).map((component) => {
      var sub = newSub[component.id];

      if(Object.entries(defaultSubmission).length > 0){
        if(defaultSubmission[component.id]){
          defaultSubmission[component.id].map((defaultSub, defaultIndex) =>{
            if(defaultSub.changed){
              sub[defaultIndex] = defaultSub.value;
            }
          })
        }
      }

      if(actualRows > defaultData){
        var dif = actualRows - defaultData;
        for(let i = 0; i <= (dif - 1); i++){
          sub.splice(sub.length - 1, 1)
        }
      }else if(defaultData > actualRows){
        var dif = defaultData - actualRows;
        for(let i = 0; i <= (dif - 1); i++){

          switch(component.type){
            case "datetime":
                sub.push(initialDateTime);
              break;
            case "dropdown":
                sub.push(choicelist[component.object_type_id][0]["id"]);
              break;
              case
                   "signature",
                   "timer",
                   "timerauto",
                   "document":
                sub.push(0);
              break;
            case "yesno",
                 "yesnoall":
                sub.push(0);
              break;
            default:
                sub.push("");
              break;
          }
        }
      }
      newSub[component.id] = sub;
    })
    this.setState({ submission: newSub, components: newComponents, rerenderTable: !this.state.rerenderTable });
  }


  render() {
    const { submission, choicelist, checkboxkey, showPaymentInfo, callOnSave, components, lotConsecutive, formLoaded, rerenderTable } = this.state;
    const { submissionSelected, formSubmission, isLoadingIssue, companyTimezone, promptMessages } = this.props;
    const status = submissionSelected ? submissionSelected.status : "new";
    let issues = submissionSelected ? submissionSelected.issues : null;
    if (this.state.tmpIssues.length > 0)
      issues = this.state.tmpIssues;
    const submissionId = submissionSelected ? submissionSelected._id : null;
    console.log(issues);
    return (
      <View style={{ flexDirection: "row", }}>
        <PaymentInfo visible={showPaymentInfo} onSave={this.submitFormSave.bind(this)} callOnSave={callOnSave} />
        <View style={{ flex: 2, }} />
        <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
          {
            this.props.isLoaded || (this.props.isSaving && this.state.autosave) ? (

              <ScrollView
                contentContainerStyle={styles.innerView}
              >
                {
                  status != "new" ? 
                    <View>
                      <Text style={styles.reportId}>Report Id: {submissionId}</Text>
                    </View>
                  : null
                }
                

                {components.filter(section => section.type == 'section').map((section, id) => (

                  section.direction && section.direction == 'table' ?
                    <Section
                      showSeparator={id === 0}
                      isLast={id == components.filter(section => section.type == 'section').length - 1}
                      label={section.label}
                      onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                      id={section.id}
                      media={section.attachment}
                      formSubmission={formSubmission}
                      attachment_type={section.attachment_type}
                      mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                      formId={this.props.formId}
                      setBigFileIsLoading={this.props.setBigFileIsLoading}
                      direction={section.direction}
                      defaultData={section?.defaultData ? section?.defaultData : 1}
                      section={section}
                      components={components}
                      choicelist={choicelist}
                      checkboxkey={checkboxkey}
                      onSaveDefault={(defaultData, defaultSubmission, dataType)=>{
                        this.editTableRows(defaultData, section?.section_id, defaultSubmission, dataType);
                      }}
                      defaultSubmission={section?.defaultSubmission ? section?.defaultSubmission : {}}
                      rerenderTable={rerenderTable}
                      status={status}
                    >
                      <View>
                        <ScrollView horizontal={true}>
                          <View style={{ marginTop: 10, zIndex: 'unset' }}>
                            <Table style={{ justifyContent: "flex-start", zIndex: 'unset' }} borderStyle={{ borderWidth: 2, borderColor: '#fff', justifyContent: "flex-start", zIndex: 'unset' }}>
                              <Row data={this.getTableHeaders(components, section.section_id)} widthArr={this.getCellArrayWidth(components, section.section_id)} style={styles.head} textStyle={{ ...styles.text, color: 'white' }} />
                              {
                                this.checkForRows(components, section.section_id) ?
                                  this.renderRows(this.checkForRows(components, section.section_id), components, section.section_id)
                                  :
                                  <Row data={this.getRowComponents(components, section.section_id, null)} widthArr={this.getCellArrayWidth(components, section.section_id)} style={{ backgroundColor: '#EDEEEE', justifyContent: "flex-start", zIndex: 'unset' }} textStyle={styles.text} />
                              }


                            </Table>
                          </View>
                            
                        </ScrollView>
                        <NewIssue
                            visible={this.state.openTableIssue} 
                            id={this.state.compIssueId} 
                            label={this.state.issueLabel} 
                            onSubmitIssue={(issue) => {
                              this.props.loadingIssue(this.state.compIssueId);
                              this.setState({rerenderTable: !this.state.rerenderTable});
                              this.saveIssue({ ...issue, position: this.state.issuePosition }, issues, submissionId,()=>{
                                setTimeout(() => {
                                  this.props.loadingIssue(null);
                                  this.setState({rerenderTable: !this.state.rerenderTable});
                                }, 1000);
                              });
                            }}
                            handleClose={() => {
                              this.setState({
                                openTableIssue:false,
                                compIssueId: null,
                                issueLabel: '',
                                issuePosition: null,
                                issueWidth: "small",
                                rerenderTable: !rerenderTable
                            })
                            }} 
                            width={this.state.issueWidth}
                        />
                      </View>
                      { (status != 'archived' && status != 'approved') ?
                        <View style={styles.buttonsContainer}>
                          <TouchableOpacity style={styles.btnAdd}
                            onPress={() => {this.addTableRow(section.section_id, choicelist)}}
                          >
                            <Text>
                              <Icon name="plus-circle-outline" style={{ fontSize: 30, }} />
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity style={styles.btnAdd}
                            onPress={() => {
                              var rows = this.checkForRows(components, section.section_id);
                              if(rows)this.restTableRow(section.section_id)
                            }}
                          >
                            <Text>
                              <Icon name="close-circle-outline" style={{ fontSize: 30, }} />
                            </Text>
                          </TouchableOpacity>
                        </View> : null
                      }
                    </Section>
                    :

                    <Section
                      showSeparator={id === 0}
                      isLast={id == components.filter(section => section.type == 'section').length - 1}
                      label={section.label}
                      onChange={(id, value, subtype) => this.onComponentChange(id, value, subtype)}
                      id={section.id}
                      media={section.attachment}
                      formSubmission={formSubmission}
                      attachment_type={section.attachment_type}
                      mediaId={typeof section.attachmentId !== 'undefined' ? section.attachmentId : null}
                      formId={this.props.formId}
                      choicelist={choicelist}
                      setBigFileIsLoading={this.props.setBigFileIsLoading}
                      checkboxkey={checkboxkey}
                      defaultSubmission={{}}
                      rerenderTable={rerenderTable}
                      status={status}
                    >
                      {components.filter(component => component.section_id == section.section_id && component.type != 'section').map((component) => {
                        let new_label;
                        switch (component.type) {
                          case "customlotcode":
                            new_label = `${component.label} - ${component.lotcodetypename}`;
                            break;
                          case "inventory":
                            new_label = `${component.label} - ${component.inventorytypename} Code`;
                            if (typeof submission[component.id] == "undefined")
                              submission[component.id] = { lotcode: "", productname: "", quantity: "" };
                            break;
                          default:
                            new_label = `${component.label}`;
                        }

                        return <>
                          {
                            isLoadingIssue != null && component.id == isLoadingIssue ?
                              <ActivityIndicator size={150} color={colors.primary}></ActivityIndicator>
                              :
                              <View>

                                <FormComponent
                                  id={component.id}
                                  type={component.type}
                                  key={component.id}
                                  label={component.mandatory && component.type != 'datetime' && component.type != 'datetime' ? new_label + " *" : new_label}
                                  placeholder={component.placeholder ? component.placeholder : ""}
                                  componentLink={component.componentLink ? component.componentLink : ""}
                                  enabledLink={component.enabledLink ? component.enabledLink : ""}
                                  initial={submission[component.id]}
                                  onChange={(id, value, subtype) => {
                                    this.onComponentChange(id, value, subtype)

                                  }}
                                  options={choicelist[component.object_type_id]}
                                  checkboxkey={checkboxkey}
                                  status={submissionSelected ? submissionSelected.status : false}
                                  submissionSelected={submissionSelected ? submissionSelected : null}
                                  showLotGenerator={component.showLotGenerator ? component.showLotGenerator : null}
                                  lotConsecutive={lotConsecutive}
                                  customlot={false}
                                  isIssuable={submissionId != null}
                                  onSubmitIssue={(issue) => {
                                    this.props.loadingIssue(component.id);
                                    this.setState({rerenderTable: !this.state.rerenderTable});
                                    this.saveIssue(issue, issues, submissionId,()=>{
                                      setTimeout(() => {
                                        this.props.loadingIssue(null);
                                        this.setState({rerenderTable: !this.state.rerenderTable});
                                      }, 1000);
                                    });
                                  }}
                                  companyTimezone={companyTimezone}
                                  defaultComponent={(status == 'archived' || status == 'approved') || formSubmission ? true : false}
                                  promptMessages={promptMessages}
                                />
                                {issues && this.renderIssue(issues, component) != null ? <View style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 10,
                                  marginBottom: 10,

                                }}>
                                  {this.printAllIssues(issues, component, null, false)}
                                </View> : null}

                              </View>
                          }

                        </>



                      })}
                    </Section>


                ))}
                {
                  formSubmission ?
                    null :
                    <RecordActions setCompleteStatus={() => { this.setCompleteStatus() }}
                      formLoaded={formLoaded}
                      submitForm={(status) => status ? this.submitForm(status) : this.submitForm()}
                      submitFormSave={(status) => status ? this.submitFormSave(status) : this.submitFormSave()} record={this.props.submissionSelected} navigateTo={(page) => this.props.navigation.navigate(page)} />

                }
                {
                  formSubmission ?
                    null :
                    this.showSave()
                }
                {
                  formSubmission ?
                    <TouchableOpacity style={styles.readOnlyContainer}>
                      <Text style={{ alignSelf: "center" }}>This record is read only, the form was deleted from the admin and this record can not be modified.</Text>
                    </TouchableOpacity> : null
                }



              </ScrollView>
            ) : this.props.isLoading ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            ) : this.props.isSaving && !this.state.autosave ? (
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Saving...</Text>
              </View>
            ) : this.props.isLoadingBigFile ?
              <View style={styles.fixedContainer}>
                <ActivityIndicator></ActivityIndicator>
                <Text style={styles.loadingText}>Uploading...</Text>
              </View>
              : (
                <View style={styles.fixedContainer}>
                  <View style={styles.fixedContainerHT}>
                    <MaterialCommunityIcons
                      name="alert-circle"
                      size={32}
                      color="#d00"
                      style={styles.icon}
                    />
                    <Text style={styles.errorText}>{this.props.error}</Text>
                  </View>
                  <View style={styles.fixedContainerHB}>
                    <TouchableOpacity
                      onPress={() => {
                        this.props.retry(
                          this.props.isErrorSave,
                          this.props.components,
                          this.props.choicelist,
                          this.state.submission,
                          this.props.newReport ? uuid.v1() : this.state.uuid,
                          this.props.task_id
                        );
                      }}
                    >
                      <Text>Retry</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )}

          <LogDialog />
        </View>
        <View style={{ flex: 2 }} />
      </View>
    );


  }
}

export default connect(
  (state) => {
    return {
      isLoading: state.forms.getIn(["form", "status"]) === "loading",
      isLoadingBigFile: state.forms.getIn(["form", "status"]) === "loading_big_file",
      isErrorSave: state.forms.getIn(["form", "status"]) === "errorSaving",
      isErrorRetrieve:
        state.forms.getIn(["form", "status"]) === "errorRetrieving",
      formId: state.forms.getIn(['form', 'id']),
      isLoaded: state.forms.getIn(["form", "status"]) === "loaded",
      isSaving: state.forms.getIn(["form", "status"]) === "saving",
      error: state.forms.getIn(["form", "errorMessage"]),
      components: state.forms.getIn(["form", "components"]),
      choicelist: state.forms.getIn(["form", "choicelist"]),
      formSubmission: state.forms.getIn(["form", "formSubmission"]),
      previousSubmissions: state.submissions.get("previousSubmissions"),
      /*syncState: state.sync.get("state"),*/
      isLogGroup: state.loggroups.get("isLogGroup"), 
      logGroupSelected: state.loggroups.get("logGroupSelected"),
      submissionSelected: state.submissions.get("submissionSelected"),
      isSubmission: state.submissions.get("isSubmission"),
      isLoadingIssue: state.submissions.get("loadingIssue"),
      payment_completed: state.user.get("payment_completed"),
      companyTimezone: state.user.get("companyTimezone"),
      taskSelected: state.tasks.get("taskSelected"),

    };
  },
  (dispatch, props) => {

    return {
      sendSubmit: (submissionId, submissions, comment, callback) => dispatch(sendSubmit(submissionId, submissions, comment, callback)),
      checkRecordStatus: (uuid, callback) => dispatch(checkRecordStatus(uuid, callback)),
      showStatusError: (status) => dispatch(showStatusError(status)),
      checkLogStatus: (task_id, callback) => dispatch(checkLogStatus(task_id, callback)),
      showLogStatusError: (status) => dispatch(showLogStatusError(status)),
      loadingIssue: (value) => dispatch(loadingIssue(value)),
      setBigFileIsLoading: (payload) => dispatch(setBigFileIsLoading(payload)),
      getForm: (idSubmission, callback) => {
        dispatch(getForm(props.formId, idSubmission, callback));
      },
      editDefaultData: (data, callback) => dispatch(editDefaultData(data, callback)),
      setStatusChange: () => { dispatch(setStatusChange()) },
      unsetStatusChange: () => { dispatch(unsetStatusChange()) },
      setLogGroupsSubAction: () => {
        dispatch(setLogGroupsSubAction());
      },
      retry: (
        isErrorSave,
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        status
      ) => {
        if (isErrorSave) {
          dispatch(
            beginSubmit(
              props.formId,
              props.title,
              components,
              choicelist,
              submission,
              uuid,
              task_id,
              false,
              status
            )
          );
        } else {
          dispatch(getForm(props.formId, props.submissionSelected?._id));
        }
      },
      startSubmit: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        logroup,
        status
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            false,
            logroup,
            status
          )
        );
      },
      autoSave: (
        components,
        choicelist,
        submission,
        uuid,
        task_id,
        autosave
      ) => {
        dispatch(
          beginSubmit(
            props.formId,
            props.title,
            components,
            choicelist,
            submission,
            uuid,
            task_id,
            autosave
          )
        );
      },
    };
  }
)(Form);

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#B3B7BC",
    flex: 1,
    flexDirection: "column",
  },
  issueContainer: {
    flex: 1,
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: 'pink',
  },
  readOnlyContainer: {
    flexGrow: 1,
    backgroundColor: "lightblue",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
  container: {
    flex: 1,
    backgroundColor: "#B3B7BC",
    flexDirection: "column",
    justifyContent: "center",
  },
  keyboardAvoid: {
    flex: 1,
  },
  loadingText: {
    color: "#444",
    marginTop: 5,
  },
  fixedContainer: {
    flex: 1,
    flexDirection: "column",
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
  },
  fixedContainerHB: {
    marginTop: 20,
  },
  errorText: {
    color: "#444",
    marginLeft: 5,
  },
  innerView: {
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#fff",
    marginBottom: 20,
    shadowOffset: {
      width: 7,
      height: 5,
    },
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  menuContainer: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20,
  },
  submitContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.gray_darken_2,
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    height: 60,
  },
  buttonText: {
    color: colors.primary,
    fontSize: 18,
    fontFamily: "Roboto",
  },
  iconInProgress: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconEditRequired: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForReview: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgray',
  },
  iconReadyForApproval: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightblue',
  },
  iconApproved: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'lightgreen',
  },
  iconRejected: {
    marginHorizontal: 10,
    fontSize: 30,
    alignSelf: "center",
    color: 'pink',
  },
  issueBox: {
    alignItems: "center",
    backgroundColor: 'pink',
    borderRadius: 5,
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 10,
    marginBottom: 10,
    height: 60,
  },
  issueText: {
    fontSize: 18,
    fontFamily: "Roboto",
    color: "#444",
  },
  readOnlyTxt: {
    fontSize: 22,
    borderRadius: 4,
    backgroundColor: 'pink',
    margin: 10,
    padding: 10,
    fontFamily: "Roboto",
  },
  viewContainerMax: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: 20,
    marginTop: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  head: {
    backgroundColor: colors.secondary, 
    color: 'white'
  },
  text: {
    margin: 6
  },
  btnAdd: {
    width: 40
  },
  buttonsContainer:{
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 10,
  },
  reportId:{
    paddingLeft: 10,
    fontSize: 18,
    fontWeight: 'bold',
    color: "#4eafa9"
  }

});