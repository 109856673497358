import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "../../constants/colors";

export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  // set initial value
  componentWillMount(nextProps, nextState) {
    this.setState({ value: this.props.initial });
  }

  render() {
    const { value } = this.state;
    const { onPress, label, defaultComponent } = this.props;
    return (
      <View style={styles.container}>
        <TouchableOpacity
          disabled={defaultComponent}
          style={styles.button}
          onPress={() => {
            onPress(1 - value);
            this.setState({ value: 1 - value });
          }}
        >
          <View style={styles.checkBox}>
            {value ? (
              <MaterialCommunityIcons
                name="check"
                size={20}
                color={colors.secondary}
                style={styles.icon}
              />
            ) : null}
          </View>
          <Text style={styles.label}>{label}</Text>
        </TouchableOpacity>
        <View style={styles.borderBottom} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    position: "relative",
    paddingBottom: 5,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    //height: 30,
    flex: 1,
    paddingTop: 5,
    paddingBottom: 5,
  },
  checkBox: {
    marginLeft: 10,
    marginRight: 5,
    width: 22,
    height: 22,
    borderStyle: "solid",
    borderColor: colors.secondary,
    borderWidth: 1,
    borderRadius: 4,
  },
  icon: {
    width: 20,
    height: 20,
  },
  label: {
    fontSize: 18,
    color: "#424242",
    fontWeight: "300",
    fontFamily: "Roboto",
    flex: 1,
  },
  borderBottom: {
    
    marginLeft: 10,
    marginTop: 5,
    backgroundColor: "#e7e7e7",
  },
});
