import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableHighlight,
  TextInput,
  ScrollView,
  Modal,
} from "react-native";
// import Modal from 'react-native-modalbox';
import CheckBox from "../widgets/checkbox";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import colors from "../../constants/colors";
import { payWithBambora } from "../../api";
import { connect } from "react-redux";
import { setPaymentCompleted } from "../../actions/register";
import { logout } from "../../actions/login";
import Animations from '../animations';
import { providences } from "../../constants/providences";
// import { Actions } from "react-native-router-flux";
import Swal from "sweetalert2";

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      termsAgreed: false,
      showCardDetails: false,
      name: "",
      validName: false,
      card_number: "",
      validCard: false,
      exp_month: "",
      validMonth: false,
      exp_year: "",
      validYear: false,
      cvd: "",
      validCVD: false,
      exp_date: "",
      loading: false,
      postal_code: '',
      validPostal: false,
      city: '',
      validCity: false,
      country: 'US',
      email_address: '',
      validEmail: false,
      address_line1: '',
      validAddress1: false,
      address_line2: '',
      validAddress2: true,
      provinces: [],
      province: '',
    };
  }

  componentDidMount() {
    this.setState({ visible: this.props.visible, provinces: providences['US'] });
  }
  
  componentDidUpdate(prevProps, prevState){
    if(prevProps.visible != this.props.visible){
      this.setState({ visible: this.props.visible});
    }
  }

  isValid(){
    const {validName, validCard, validMonth, validYear, validCVD, validPostal, validCity, validEmail, validAddress1, validAddress2} = this.state;
    return (validName && validCard && validMonth && validYear && validCVD && validPostal && validCity && validEmail && validAddress1 && validAddress2);
  }

  render() {
    const {
      visible,
      termsAgreed,
      name,
      validName,
      card_number,
      validCard,
      exp_month,
      validMonth,
      exp_year,
      validYear,
      cvd,
      validCVD,
      exp_date,
      showCardDetails,
      loading,
      postal_code,
      validPostal,
      city,
      validCity,
      country,
      email_address,
      validEmail,
      address_line1,
      validAddress1,
      address_line2,
      validAddress2,
      province,
      provinces
    } = this.state;
    
    return (
      <Modal animationType="slide" visible={visible}>
        <ScrollView style={{ backgroundColor: "#f2f2f2", height: "100%" }}>
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#f2f2f2",
            }}
          >
            <View style={{ flex: 1 }} />
            <View
              style={{
                flex: 1,
                backgroundColor: "#fff",
                padding: 50,
                borderRadius: 20,
                marginTop: 10,
                minWidth: 400,
                marginBottom: 10,
              }}
            >
              <View style={{ position: "relative" }}>
                <TouchableHighlight
                  style={{
                    position: "absolute",
                    top: -44,
                    right: -44,
                    backgroundColor: "#fff",
                    borderRadius: 20,
                    padding: 10,
                    zIndex: 10,
                  }}
                  activeOpacity={0.5}
                  onPress={() => {
                    this.setState({ visible: false })
                    if(this.props.callOnSave){
                      this.props.onSave();
                    }
                    this.props.logoff();
                    location.reload();
                  }}
                >
                  <Text
                    style={{
                      color: "#000",
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Close ❌
                  </Text>
                </TouchableHighlight>
              </View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {" "}
                An automatic charge to your credit card will proceed as per the
                Service Agreement, once the first version of your work is ready
                for your review and approval
              </Text>
              {showCardDetails && (
                <View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={name}
                      style={[styles.inputPass]}
                      placeholder={"Name On Card"}
                      onChangeText={(val) => {
                        if(val.split(' ').length > 1)
                          this.setState({validName : true});
                        else
                          this.setState({validName : false});
                  
                        this.setState({ name: val });
                      }}
                    />
                    { validName && 
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={card_number}
                      style={[styles.inputPass]}
                      placeholder={"Card Number"}
                      onChangeText={(val) => {
                        if(val.length === 16)
                          this.setState({ validCard : true })
                        else
                          this.setState({ validCard : false })

                        this.setState({ card_number: val });
                      }}
                    />
                    { validCard &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                      <MaterialCommunityIcons
                        name="close-circle-outline"
                        size={32}
                        color={colors.success}
                        style={
                          styles.icon
                        }
                        onPress = { () => this.setState({exp_date: ''})}
                      />
                    <TextInput
                      value={exp_date}
                      style={[styles.inputPass]}
                      placeholder={"Expiration Date : mm/yy"}
                      onChangeText={(val) => {
                        let nVal = "";
                        switch (val.length) {
                          case 1:
                            nVal = val + '/';
                            break;
                          case 2:
                            nVal = val;
                          case 3:
                              nVal =
                                val.charAt(0) +
                                val.charAt(2) +
                                "/" ;
                            break;
                          case 4:
                          default:
                            nVal =
                              val.charAt(0) +
                              val.charAt(1) +
                              "/" +
                              val.charAt(3) +
                              val.charAt(4);
                            break;
                        }
                        const month  = nVal.charAt(0) + nVal.charAt(1);
                        const year = nVal.charAt(3) + nVal.charAt(4);
                        this.setState({
                          exp_month: month,
                          exp_year: year,
                          exp_date: nVal,
                        })
                        var strDate = new Date(); // By default Date empty constructor give you Date.now
                        var shortYear = strDate.getFullYear(); 
                        var twoDigitYear = shortYear.toString().substr(-2);
                        if( parseInt(month) <= 12 && parseInt(year) >= twoDigitYear  )
                          this.setState({ validYear: true, validMonth: true });
                        else
                          this.setState({ validYear: false, validMonth: false });
                      }}
                    />
                    { (validMonth && validYear) &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={cvd}
                      style={[styles.inputPass]}
                      placeholder={"Card cvd"}
                      onChangeText={(val) => {
                        if(val.length == 3)
                          this.setState({validCVD : true})
                        else
                          this.setState({validCVD : false})
                        
                        this.setState({ cvd: val });
                      }}
                    />
                    { validCVD &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={address_line1}
                      style={[styles.inputPass]}
                      placeholder={"Address Line 1"}
                      onChangeText={(val) => {
                        if(val.length <= 64)
                          this.setState({ address_line1: val, validAddress1: true });
                        else
                          this.setState({ address_line1: val ,validAddress1: false })
                      }}
                    />
                    { validAddress1 &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={address_line2}
                      style={[styles.inputPass]}
                      placeholder={"Address Line 2"}
                      onChangeText={(val) => {
                        if(val.length <= 64)
                          this.setState({ address_line2: val, validAddress2: true });
                        else
                          this.setState({ address_line2: val, validAddress2: false })
                      }}
                    />
                    { validAddress2 &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={postal_code}
                      style={[styles.inputPass]}
                      placeholder={"Postal/Zip Code"}
                      onChangeText={(val) => {
                        if( /^([0-9]{5})$/.test(val) )
                          this.setState({ postal_code: val, validPostal: true });
                        else
                          this.setState({ postal_code: val, validPostal : false })
                      }}
                    />
                    { validPostal &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={city}
                      style={[styles.inputPass]}
                      placeholder={"City"}
                      onChangeText={(val) => {
                        if( val.length <= 32 )
                          this.setState({ city: val, validCity: true });
                        else
                          this.setState({ city: val, validCity: false });
                      }}
                    />
                    { validCity &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <select value={country} onChange={ (e) => { 
                        this.setState({ country: e.target.value, validCounty: true, provinces: providences[e.target.value]}) 
                      }} 
                      style={{flex:1}}>
                      <option value="US">U.S.A.</option>
                      <option value="CA">Canada</option>
                      <option value="MX">Mexico</option>
                    </select>
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <select value={province} onChange={ (e) => { 
                        this.setState({ province: e.target.value}) 
                      }} 
                      style={{flex:1}}>
                      {provinces.map( prov => <option value={prov.abbreviation}>{prov.name}</option>)}
                    </select>
                  </View>
                  <View style={styles.fixedContainerHT}>
                    <TextInput
                      value={email_address}
                      style={[styles.inputPass]}
                      placeholder={"Email"}
                      onChangeText={(val) => {
                        if( /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/.test(val) )
                          this.setState({ email_address: val, validEmail: true });
                        else
                          this.setState({ email_address: val, validEmail: false });
                      }}
                    />
                    { validEmail &&
                      <MaterialCommunityIcons
                        name="check"
                        size={32}
                        color={colors.success}
                        style={
                          (styles.iconEye, { position: "absolute", right: 10 })
                        }
                      />
                    }
                  </View>
                  
                </View>
              )}

              <View style={{ alignContent: "center", alignItems: "center" }}>
                <CheckBox
                  initial={termsAgreed}
                  onPress={(val) => {
                    this.setState({ termsAgreed: !termsAgreed });
                  }}
                  label={"I have read and agree to the Terms"}
                />
              </View>
              <TouchableHighlight
                disabled={loading}
                style={styles.button}
                onPress={() => {
                  if (showCardDetails && termsAgreed && this.isValid() ) {
                    this.setState({loading: true});
                    this.props.pay(
                      {
                        name,
                        card_number,
                        exp_month,
                        exp_year,
                        cvd,
                        address_line1,
                        address_line2,
                        city,
                        country,
                        postal_code,
                        email_address,
                        province : province != "" ? province : provinces[0]?.abbreviation 
                      },
                      (resp) => {
                        this.props.setPaid(resp.payment_completed);
                        if(resp.payment_completed) {
                          this.setState({ visible: false });
                          Swal.fire({
                            text: "Payment completed successfully, thank you!",
                            confirmButtonText: "Close",
                            backdrop: false

                          });
                        } else {
                          Swal.fire({
                            text: `Unfortunately the payment could not be completed due the following reason: ${resp?.message}`,
                            confirmButtonText: "Close",
                            backdrop: false

                          });
                        }
                        this.setState({loading:false});
                      }
                    );
                  }
                  else {
                    Swal.fire({
                      text: `Please fill out all the card information and accept the terms`,
                      confirmButtonText: "Close",
                      backdrop: false

                    });
                  }
                  this.setState({ showCardDetails: true });
                }}
              >
                <View style={{flexDirection:'column'}}>
                  { loading && <Animations animationfile='loading-dots'/> }
                  <Text style={styles.textButton}>Payment</Text>
                </View>
              </TouchableHighlight>
            </View>
            <View style={{ flex: 1 }}></View>
          </View>
        </ScrollView>
      </Modal>
    );
  }
}

export default connect(
  (state) => {
    return {};
  },
  (dispatch, props) => {
    return {
      pay: (params, callback) => dispatch(payWithBambora(params, callback)),
      setPaid: (paid) => dispatch(setPaymentCompleted(paid)),
      logoff: () => dispatch(logout()),
    }
  }
)(PaymentInfo);

const styles = StyleSheet.create({
  textButton: {
    color: '#fff',
  },
  button: {
    backgroundColor: colors.secondary,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.secondary,
    padding: 10,
    margin: 10,
  },
  buttonDisabled: {
    backgroundColor: "gray",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.secondary,
    padding: 10,
    margin: 10,
  },
  fixedContainerHT: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  inputPass: {
    width: "100%",
    height: 58,
    paddingLeft: 50,
    marginLeft: 0,
    paddingBottom: 0,
    fontSize: 15,
    fontFamily: "Roboto",
    color: "#444",
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
    borderRadius: 6,
  },
  icon: {
    position: "absolute",
    left: 12,
    color: colors.gray_darken_2,
  },
  iconEye: {
    position: "absolute",
    right: 0,
    color: colors.gray_darken_2,
  },
});
