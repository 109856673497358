import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import colors from "../../constants/colors";
import PropTypes from "prop-types";
class ChipOutline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      background: "white",
    };
  }
  componentDidMount() {
    let background = "";
    switch (this.props.type) {
      case "success":
        background = colors.success;
        break;
      case "primary":
        background = colors.primary;
        break;
      case "error":
        background = colors.error;
        break;
      case "info":
        background = colors.gray;
        break;
      case "orange":
        background = colors.accent;
        break;
      case "ambar":
        background = colors.ambar;
        break;
      default:
        background = colors.blue_gray;
    }
    this.setState({
      background: background,
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.type !== this.props.type) {
      let background = "";
      switch (this.props.type) {
        case "success":
          background = colors.success;
          break;
        case "primary":
          background = colors.primary;
          break;
        case "error":
          background = colors.error;
          break;
        case "info":
          background = colors.gray;
          break;
        case "orange":
          background = colors.accent;
          break;
        case "ambar":
          background = colors.ambar;
          break;
        default:
          background = colors.blue_gray;
      }
      this.setState({
        background: background,
      });
    }
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          {
            maxWidth: (this.props.label.length + 3) * 10,
            borderColor: this.state.background,
          },
        ]}
      >
        <Text style={[styles.chipText, { color: this.state.background }]}>
          {this.props.label}
        </Text>
      </View>
    );
  }
}
export default ChipOutline;

ChipOutline.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 60,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    borderWidth: 1,
  },
  chipText: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "center",
  },
});
