import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    View,
    StyleSheet,
    TouchableOpacity,
    Text
 } from 'react-native';
 import { checkRecordStatus, showStatusError } from '../../actions/menu';
import { 
    recordInProgress, 
    recordEditRequired, 
    recordComplete,
    recordApproved,
} from "../../actions/records";
import {   
  removeTaskAction, getTasks,
} from "../../actions/tasks";
import colors from "../../constants/colors";
import Animations from "../animations";

export class RecordActions extends Component {
    constructor(props){
        super(props);
        this.state ={   
          loading: false        
        };
    }
    componentWillUnmount(){
      this.setState({loading: false})
    }
    showCommands(){  
        const {record,statusLoading,taskSelected, preventStatusChange, submissionSelected, formLoaded} = this.props;        
        const { loading } = this.state;
        const isLog = !(typeof taskSelected.log_group_id === 'undefined');
        console.log("preventStatusChange on recordActions  "+preventStatusChange)
        if(!isLog && (record == null || typeof record.status === 'undefined' || record.status==false|| record.status == 'pending' || record.status == 'signed' || record.status == "in_progress" || record.status == "edit_required" || record.status == "ready_for_review" || record.status == "ready_for_approval" || record.status == "rejected")){
         return (
           <View>
             <View style={styles.submitContainer}>
             <TouchableOpacity
               style={styles.button}               
               onPress={async () => {
                   if(!loading && formLoaded ){
                      this.setState({loading: true})
                      //Validate Status
                      var id = taskSelected ? taskSelected?._id : null;
                      if(!id){
                        id = submissionSelected.uuid;
                      }
                      this.props.checkRecordStatus(id, async (status) =>{
                        if(!status || status == "in_progress" || status == "pending" || status == "edit_required"){
                            await this.props.submitForm('complete');
                            this.setState({loading: false})
                        }else{
                          this.props.showStatusError(status);
                          this.props.navigateTo('MainView');
                          this.setState({loading: false})
                        }
                      })
                                          
                                                    
                  }     
               }}>
              <View style={{display: 'flex', flexDirection:'row'}}>
              {statusLoading ?
               <Text style={styles.buttonText2}>
                 Send Report
               </Text>:
               <Text style={styles.buttonText}>
               Send Report
             </Text>
                }
               {statusLoading ? <Animations animationfile='loading-dots' /> : null}
               </View>
             </TouchableOpacity>
             </View>            
           </View>
         );
        }
     
        if(!isLog && record.status == "complete"){
         return (
           <View>
              <View style={styles.submitContainer}>
             <TouchableOpacity
               style={styles.button}
               underlayColor={"#50C5BE"}
               onPress={async () => {
                   if(!loading && formLoaded){
                    this.setState({loading: true})
                    //Validate Status
                    var id = taskSelected ? taskSelected?._id : null;
                    if(!id){
                      id = submissionSelected.uuid;
                    }
                    this.props.checkRecordStatus(id, async (status) =>{
                      if(status == "approved" || status == "archived" ){
                        this.props.showStatusError(status);
                        this.props.navigateTo('MainView');
                        this.setState({loading: false})
                      }else{
                        // await this.props.recordEditRequired(record);
                        await this.props.submitFormSave('edit_required');
                        this.setState({loading: false})
                      }
                    });
                   }          
                 
               }}
             >
               <View style={{display: 'flex', flexDirection:'row'}}>
                  {statusLoading ?
                  <Text style={styles.buttonText2}>
                    Edit required
                  </Text>:
                  <Text style={styles.buttonText}>
                  Edit required
                </Text>
                    }
                  {statusLoading ? <Animations animationfile='loading-dots' /> : null}
               </View>
             </TouchableOpacity>       
             </View>  
             {record.status != "approved"?
               <View style={styles.submitContainer}>
               <TouchableOpacity
                 style={styles.buttonSecondary}
                 underlayColor={"#50C5BE"}
                 onPress={async () => {     
                     if(!loading && formLoaded){
                        this.setState({loading: true});
                        //Validate Status
                        var id = taskSelected ? taskSelected?._id : null;
                        if(!id){
                          id = submissionSelected.uuid;
                        }
                        this.props.checkRecordStatus(id, async (status) =>{
                          if(status == "edit_required" || status == "approved" || status == "archived" ){
                            this.props.showStatusError(status);
                            this.props.navigateTo('MainView');
                            this.setState({loading: false});
                          }else{
                            // await this.props.recordApproved(record);
                            await this.props.submitForm('approved');
                            this.setState({loading: false});
                          }
                        }); 
                     }               
                 }}
               >
                 <View style={{display: 'flex', flexDirection:'row'}}>
                  {statusLoading ?
                    <Text style={styles.buttonTextSecondary2}>
                      Approve Report
                    </Text>:
                    <Text style={styles.buttonTextSecondary}>
                    Approve Report
                  </Text>
                      }
                  
                  {statusLoading ? <Animations animationfile='loading-dots' /> : null}
                 </View>
               </TouchableOpacity>       
               </View> : null 
             }             
           </View>
         );
        }

        if(isLog || record.status == 'archived' || record.status == 'approved'){
          return null;
        }
         
    }
    
    render() {        
        return (
            this.showCommands()
        )
    }
}

const mapStateToProps = (state) => {
    return {
        statusLoading: state.records.get("statusLoading"),
        preventStatusChange: state.records.get("preventStatusChange"),
        taskSelected: state.tasks.get("taskSelected"),
        submissionSelected: state.submissions.get("submissionSelected"),
        taskSelected: state.tasks.get("taskSelected"),

    }    
};

export default connect(mapStateToProps,(dispatch, props)=> {
    return {
        recordInProgress : (item) => {
            dispatch(recordInProgress(item))
        },     
        recordEditRequired : (item) => {
            dispatch(recordEditRequired(item))
        }, 
        recordComplete : (item) => {
            dispatch(recordComplete(item))
        }, 
        recordApproved : (item) => {
            dispatch(recordApproved(item))
        }, 
        removeTask : (item) => {
          dispatch(removeTaskAction(item))
        }, 
        getTasks: () => {
          dispatch(getTasks());
        },
        checkRecordStatus: (uuid, callback) => dispatch(checkRecordStatus(uuid, callback)),
        showStatusError: (status) => dispatch(showStatusError(status)),
    }   
})(RecordActions)


const styles = StyleSheet.create({
    submitContainer: {
        flexDirection: "row",
        justifyContent: "center",
        paddingBottom: 20,
        paddingHorizontal: 10,
    },
    buttonText: {
        color: colors.userCommandText,
        fontSize: 18,
        fontFamily: "Roboto",
    },
    buttonText2: {
      color: colors.userCommandText,
      fontSize: 18,
      fontFamily: "Roboto",
      paddingTop: 15,
  },
    buttonTextSecondary: {
      color: colors.commandSecondaryText,
      fontSize: 18,
      fontFamily: "Roboto",
    },
    buttonTextSecondary2: {
      color: colors.commandSecondaryText,
      fontSize: 18,
      fontFamily: "Roboto",
      paddingTop: 15,
    },
    button: {
        alignItems: "center",
        backgroundColor: colors.userCommand,
        borderRadius: 5,
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        height: 60,
    },
    buttonSecondary: {
      alignItems: "center",
      backgroundColor: colors.commandSecondary,
      borderRadius: 5,
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      padding: 10,
      height: 60,
  },
    loadingAnimation: { 
        height: 30,
        width: 30,
        position: "absolute",
        right: 10,     
    },
});