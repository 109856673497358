import React, { Component } from "react";
import {
  Alert,
  View,
  ScrollView,
  Text,
  StyleSheet,
  FlatList,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  Platform,
  ActivityIndicator,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import SignatureCanvas from 'react-signature-canvas';
import colors from "../constants/colors";
import { connect } from "react-redux";
// import { Actions } from "react-native-router-flux";
import moment from "moment-timezone";
import { navigateForm, navigateSubmission } from "../actions/menu";
import * as Device from "expo-device";
import { getLogGroupsSubAction, getLogGroupsAction, getGroupDetail, shouldReloadLG, setLogGroupsSubActionFalse, setLogGroupSelected, setIsLogGroup } from "../actions/loggroups";
import { signTaskAction } from "../actions/tasks";
import { setSubmissionSelected, setIsSubmission } from "../actions/submissions";
import * as FileSystem from "expo-file-system";
// import ExpoPixi from "expo-pixi";
import alerts from './components/alert';
import Swal from "sweetalert2";
import "./styles.css";
import { viewContainer } from "./functions";

var isTablet = false;
var numColumns = 1;
var numAlert = 0;

class GroupView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      isLoading: true,
      signature: "",
      show: true,
      colaboratorName: "",
      isSendingData: false,
      executeLogAction: false,
      taskSelected: false,
      total: 0,
      width: window.innerWidth,
    };
    this.signatureRef = React.createRef();
  }

  handleResize = () => {
    this.setState({width: window.innerWidth});
  }

  componentDidMount() {
    this.getDeviceType();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.submissionIndicator === true) {
      this.props.getLogGroupsSubAction(this.props.taskSelected._id);
      this.props.setLogGroupsSubActionFalse();
    } 
    if (prevProps.logGroupSelected !== this.props.logGroupSelected) {
      if (!this.state.executeLogAction && this.props.logGroupSubmissions.length == 0) {
        this.props.getLogGroupsSubAction(this.props.taskSelected._id);
        this.setState({ executeLogAction: true });
      }
      this.props.getLogGroupsSubAction(this.props.taskSelected._id);
      this.setState({ isLoading: false });
    }
  }
 
  componentWillUnmount(){
    numAlert = 0;
    window.removeEventListener('resize', this.handleResize);
    this.props.setLogGroupSelected({});
    this.props.setIsLogGroup(false);
  }
  logDoesntExists(){
    if(numAlert == 0){

    Swal.fire({
      text: "This Log has been erased, please check reports on Admin",
      confirmButtonText: "OK",
      backdrop: false

    }).then((result) => {
      if (result.isConfirmed) {
        this.props.setLogGroupSelected({});
        this.props.setIsLogGroup(false);
        this.props.navigation.navigate('RecordsView');
      }
    })
    numAlert++;
  }

  }
  render() {
    const { isLoading } = this.state;
    const { taskSelected } = this.props;
    const color = 0x000000;
    const width = 5;
    const alpha = 0.5;

    return (
      <View style={styles.container}>
        {isLoading ? (
          <View
            style={{
              flex: 1,
              padding: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" color={colors.primary} />
          </View>
        ) : (
          <View style={{
            flexDirection: "row",
          }}>
            <View style={{ flex: 1, }} />
            <View className={"viewContainer"} style={viewContainer(this.state.width, styles)} >
              <View style={{ flex: 1, paddingHorizontal: 10 }}>

                <Text
                  style={[
                    styles.title,
                    { fontSize: isTablet ? 24 : 16, marginTop: isTablet ? 20 : 10 },
                  ]}


                >
                  Report category
                </Text>
                {
                  taskSelected.status != "approved" &&
                    <Text
                    style={[
                      styles.title,
                      { fontSize: isTablet ? 20 : 14, marginTop: isTablet ? 10 : 5 },
                    ]}
                    >
                      Which report do you want to complete?
                    </Text>
                }
                
                {

                  !this.props.logGroupSelected.forms  ?                      
                      this.logDoesntExists()
                     :taskSelected.status != "approved" && taskSelected.status != "archive" ?
                      this.formatData(numColumns).map((item, index) => {
                      return this._renderItem({ index, item });
                    }) : null
                }

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: isTablet ? 20 : 10,

                  }}
                >
                  <Text
                    style={[
                      styles.title,
                      { fontSize: isTablet ? 24 : 16, marginTop: isTablet ? 20 : 10 },
                    ]}
                  >
                    Entries
                  </Text>
                  <TouchableHighlight
                    onPress={() => this.props.getLogGroupsSubAction(this.props.taskSelected._id)}
                  >
                    <View>
                      <MaterialCommunityIcons
                        name="reload"
                        size={30}
                        color={colors.primary}
                      />

                    </View>
                  </TouchableHighlight>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  {this.props.logGroupSubmissions.length > 0 ? (
                    this.renderProducts()
                  ) : (
                    <View>
                      <Text
                        style={[
                          styles.title,
                          {
                            fontSize: isTablet ? 18 : 14,
                            marginTop: isTablet ? 10 : 5,
                          },
                        ]}
                      >
                        There are no reports for this log yet.
                      </Text>
                    </View>
                  )}
                  {
                    taskSelected.status != "approved" && taskSelected.status != "archive" ?
                    <>
                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: "#f3f3f3",
                          marginVertical: 10,
                        }}
                      ></View>
                      <TextInput
                        value={this.state.colaboratorName}
                        style={styles.input}
                        placeholder="Verified by: (enter name of person in charge)"
                        onChangeText={(val) => {
                          this.setState({ colaboratorName: val });
                        }}
                      />
                      <Text
                        style={[
                          styles.title,
                          {
                            fontSize: isTablet ? 18 : 14,
                            marginTop: isTablet ? 10 : 5,
                          },
                        ]}
                      >
                        Sign your report
                      </Text> 
                    </>:null
                  
                  }
                  
                  {taskSelected.status != "approved" && taskSelected.status != "archive" ?
                    <>
                      <SignatureCanvas ref={this.signatureRef}
                        onEnd={() => {
                          const imageData = this.signatureRef.current.toDataURL()
                          this.setState({ signature: imageData });

                        }}
                        backgroundColor='#ffffff'
                        canvasProps={{
                          
                          borderColor: "#efefef",
                          borderWidth: 1,
                          className: 'signature'
                        }}

                        style={{ backgroundColor: "#ffffff", minHeight: 300 }}
                      />

                      <TouchableHighlight
                        underlayColor='#20B2AA33'
                        style={styles.buttonCreate}
                        onPress={() =>
                          this.setState({ signature: "" }, () => this.cancelSignature())
                        }
                      >
                        <Text style={styles.buttonText}>Clear signature</Text>
                      </TouchableHighlight>
                    </> :null

                  }
                  {
                    taskSelected.status != "approved" && taskSelected.status != "archive" ?
                      <TouchableOpacity onPress={() => {
                      
                      if(this.state.signature != '' && this.state.colaboratorName != ''){
                        Swal.fire({
                          title: "Report Complete",
                          text: "Are you sure you want to approve this log and all associated entries?",
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                          showCancelButton: true,
                          backdrop: false
                        }).then((result) => {
                          if (result.isConfirmed) {
                            let valid = this.validateMandatoryFields();
                          
                            if (valid) {
                              this.setState({ isSendingData: true });
                              let sign = {};
                              sign.signature = this.state.signature;
                              sign.colaborator_name = this.state.colaboratorName;
                              sign.task_id = this.props.taskSelected._id;
                              sign.signed_at = moment.tz(new Date(), "UTC").format("YYYY-MM-DD HH:mm");
                              this.props.signTaskAction(sign);
                              this.props.navigation.navigate('MainView');
                            } else {
                              Swal.fire({
                                title: "Mandatory Fields",
                                text: "There are Mandatory fields in a form which are empty.",
                                confirmButtonText: "OK",
                                backdrop: false
                              
                              })
                            }
                          }
                        })
                      }else{
                        Swal.fire({
                          title: "Error",
                          text: "Please be sure to enter the name and signature.",
                          confirmButtonText: "Ok",
                          backdrop: false
                        })
                      }



                      }} style={styles.buttonSubmit} disabled={this.state.isSendingData}>
                      {this.state.isSendingData ? (
                        <ActivityIndicator size="large" color="white" style={{ padding: 15 }} />
                      ) : (
                        <Text
                          style={[
                            styles.commandText,
                            {
                              textAlign: "center",
                              fontSize: isTablet ? 24 : 16,
                            },
                          ]}
                        >
                          Approve Report
                        </Text>
                      )}
                      </TouchableOpacity>:
                      <View style={{ marginTop: 20 }}>
                        <TouchableOpacity style={styles.readOnlyContainer}>
                          <Text style={{ alignSelf: "center" }}>This log record is read only</Text>
                        </TouchableOpacity>
                      </View>
              }    

                </View>
              </View>
            </View>
            <View style={{ flex: 1 }} />
          </View>
        )}
      </View>
    );
  }

  _renderItem = ({ item, index }) => {
    if (item.isEmpty === true) {
      return (
        <View
          style={[
            styles.renderButton,
            {
              backgroundColor: "transparent",
              paddingVertical: isTablet ? 40 : 5,
            },
          ]}
        ></View>
      );
    }

    return (
      <TouchableOpacity
        key={item._id}
        onPress={() => {
          this.props.setIsSubmission(false);
          this.props.setSubmissionSelected(null);
          this.props.navigation.navigate('FormView', { formId: item._id, title: item.name, newReport: true, submission: false, task_id: this.props.taskSelected._id, log_group_id: this.props.logGroupSelected.id });
          /*this.props.selectForm(
            item.id,
            item.name,
            this.props.previousSubmissions,
            this.props.taskSelected._id,
            this.props.logGroupSelected.id
          );*/
        }}
        style={[
          styles.renderButton,
          {
            paddingVertical: isTablet ? 40 : 5,
          },
        ]}
      >
        <Text
          style={[
            styles.renderText,
            {
              fontSize: isTablet ? 24 : 16,
            },
          ]}
        >
          {item.name}
        </Text>
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "white",
            borderBottomStartRadius: 8,
            borderTopEndRadius: 8,
          }}
        >
          <MaterialCommunityIcons
            name="plus"
            size={isTablet ? 36 : 26}
            color={colors.primary}
          />
        </View>
      </TouchableOpacity>
    );
  };

  findProductName(item) {
    let itemId = "";
    var BreakException = {};
    try {
      item.components.forEach((component) => {
        switch (component.type) {
          case 'inputlabel':
          case 'input':
          case 'dropdown':
          case 'textarea': 
          case 'customlotcode':
            itemId = component.id;
            throw BreakException;
        }
      });
    } catch (e) { }
    if (typeof item.submission[itemId] === 'undefined')
      return item.name;
    else
      return item.submission[itemId];
  }

  validateMandatoryFields = () => {
    let valid = true;
    this.props.logGroupSubmissions.forEach((item, index) => {
      let subs = item.submission;
      if(item.components.length > 0){
      item.components.forEach((entry, index) => {
        if (valid) {
          if (entry.mandatory) {
            if(subs[entry.id]){
              let m = subs[entry.id];
              if (m.toString().trim().length != 0 || m != 0) {
                valid = true
              } else {
                valid = false
              }
            }else{
              valid = false
            }
            
          }
        }
      })
    }
    })
    return valid
  }

  getTimeConverted(date) {
    let d = moment.tz(date, "UTC");
    if (moment.tz(d, moment.tz.guess(true)).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
      return "Today at " + moment.tz(d, moment.tz.guess(true)).format("H:mm");
    else
      return moment.tz(d, moment.tz.guess(true)).format("MM/DD/YYYY");
  }

  renderName(val){
    if(val){
      if(typeof val == "object" && val?.length){
        return val[0];
      }else{
        return val;
      }
    }
    return null;
  }

  renderProducts() {

    let products = [];
    this.props.logGroupSubmissions.forEach((item, index) => {

      products.push(
        <View key={index} style={styles.rowReport}>
          <View>
            <Text
              style={[
                styles.textReport,
                {
                  fontSize: isTablet ? 18 : 14,
                  alignSelf: "flex-start",

                },
              ]}
            >
              {this.renderName(this.findProductName(item))}
            </Text>
            {item.status == 'edit_required' && (
              <Text
                style={[styles.textReport, {
                  fontSize: isTablet ? 18 : 14,
                  alignSelf: "flex-start",
                  color: "#E34537",
                }]}
              >
                This entry requires edition</Text>
            )}
            <Text
              style={[
                styles.textReport,
                {
                  fontSize: isTablet ? 18 : 14,
                  color: "#ccc",
                  alignSelf: "flex-start"
                },
              ]}
            >
              {this.getTimeConverted(item.updated_at)}
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              style={{
                backgroundColor: colors.gray_darken_2,
                borderRadius: 120,
                marginHorizontal: 5,
              }}
              onPress={() => {
                this.props.setSubmissionSelected({ ...item });
                this.props.setIsSubmission(true);
                this.props.navigation.navigate('FormView', { formId: item.id, title: item.name, newReport: false, submission: item.submission, task_id: this.props.taskSelected._id, log_group_id: this.props.logGroupSelected.id });
                /*this.props.navigateSubmission(
                  item.id,
                  item.name,
                  false,
                  this.props.logGroupSelected.id
                );*/
              }}
            >
              <MaterialCommunityIcons
                name="pencil"
                size={isTablet ? 30 : 20}
                color={colors.primary}
                style={{
                  padding: 10,
                  borderRadius: 120,
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    });
    return products;
  }
  formatData = (numColumns) => {
    let data = this.props.logGroupSelected.forms;
    const numberOfRows = Math.floor(data.length / numColumns);

    let numberOfElementsLastRow = data.length - numberOfRows * numColumns;

    while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow) {
      data.push({
        text: "empty",
        id: "empty",
        isEmpty: true,
      });
      numberOfElementsLastRow++;
    }
    return data.filter(x => x != null);
  };

  getDeviceType = async () => {
    let device = await Device.getDeviceTypeAsync();
    if (device === Device.DeviceType.TABLET) {
      isTablet = true;
    } else {
      isTablet = false;
    }
    numColumns = isTablet ? 3 : 1;
  };
  signatureChanged = (paths) => {
    this.setState({ signature: paths, disabled: false });
  };

  _signaturePadError = (error) => {
    //console.error(error);
  };

  _signaturePadChange = ({ base64DataUrl }) => {
    this.setState({
      signature: base64DataUrl,
      disabled: false,
    });
  };

  clearSignature() {
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }
  clearSignatureAndroid() {

    if (this.sketch.stage && this.sketch.stage.children.length > 0) {
      this.sketch.stage.removeChildren();
      this.sketch.renderer._update();
    }
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }

  cancelSignature() {

    this.signatureRef.current.clear();

  }

  handleSignature = signature => {

    this.setState({ signature });
  };

  handleEmpty() {
    console.log('Empty');
  }

  handleClear() {
    console.log('clear success!');
  }

  handleEnd(ref) {

    ref.current.readSignature();
  }
}


const mapStateToProps = (state) => {
  return {
    tasks: state.tasks.get("tasks"),
    filter: state.tasks.get("filter"),
    taskSelected: state.tasks.get("taskSelected"),
    reload: state.tasks.get("reload"),
    archiveRecently: state.tasks.get("archiveRecently"),
    previousSubmissions: state.submissions.get("previousSubmissions"),
    token: state.user.get("authToken"),
    logGroupSelected: state.loggroups.get("logGroupSelected"),
    logGroupSubmissions: state.loggroups.get("logGroupSubmissions"),
    shouldReload: state.loggroups.get("shouldReload"),
    submissionIndicator: state.loggroups.get("submissionIndicator"),

    /*syncState: state.sync.get("state"),*/
  };
};

export default connect(mapStateToProps, (dispatch, props) => {
  return {
    selectForm: (id, name, previousSubmissions, task_id, log_group_id) => {
      this.props.navigation.navigate('FormView', { formId: id, title: name, newReport: false, submission: true, task_id: task_id, log_group_id: log_group_id });
      //dispatch(navigateForm(id, name, true, task_id, log_group_id));      
    },
    setLogGroupsSubActionFalse: () => {
      dispatch(setLogGroupsSubActionFalse());
    },
    getLogGroupsSubAction: (id) => {

      dispatch(getLogGroupsSubAction(id));
    },
    shouldReloadLG: (value) => {
      dispatch(shouldReloadLG(value));
    },
    setIsSubmission: (value) => {
      dispatch(setIsSubmission(value));
    },
    setSubmissionSelected: (value) => {
      dispatch(setSubmissionSelected(value));
    },
    navigateSubmission: (id, name, newReport, log_group_id) => {
      dispatch(navigateSubmission(id, name, newReport, log_group_id));
    },
    signTaskAction: (signature) => {
      dispatch(signTaskAction(signature));
    },
    setLogGroupSelected: (data) => {
      dispatch(setLogGroupSelected(data));
    },
    setIsLogGroup: (data) => {
      dispatch(setIsLogGroup(data));
    },
    getGroupDetail: (id) => {
      dispatch(getGroupDetail(id));
    },
  };
})(GroupView);

const styles = StyleSheet.create({
  buttonSubmit: {
    flex: 1,
    borderRadius: 4,
    flexDirection: "row",
    /*textAlign: "center",*/
    justifyContent: "center",
    alignItems: "center",

    backgroundColor: colors.orange,
    paddingVertical: isTablet ? 40 : 5,
  },
  input: {
    borderColor: "#e7e7e7",
    backgroundColor: "#FFF",
    borderRadius: 5,
    borderWidth: 1,
    marginVertical: 10,
    color: "#424242",
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "400",
    height: 65,
    paddingLeft: 10,
  },
  buttonCreate: {
    height: 60,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
    borderRadius: 12,
    borderColor: colors.gray_darken_2,
    borderWidth: 1,
  },
  buttonText: {
    fontSize: 14,
    color: colors.primary,
  },
  container: {
    flex: 1,
    backgroundColor: "#f6f6f6",
  },
  renderButton: {
    borderRadius: 12,
    backgroundColor: colors.gray_darken_2,
  },
  renderText: {
    fontFamily: "Roboto-Bold",
    color: colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  commandText: {
    fontFamily: "Roboto-Bold",
    color: "white",
    paddingHorizontal: 10,
    paddingVertical: 20,
  },
  rowReport: {
    backgroundColor: "white",
    padding: 10,

    borderRadius: 8,
    flex: 1,
    marginVertical: 2,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  signature: {
    backgroundColor: "#fff",
    borderRadius: 5,
    overflow: "hidden",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    marginBottom: 10,
  },
  textReport: {
    fontFamily: "Roboto-Medium",
    alignSelf: "center",
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  title: {
    fontFamily: "Roboto-Bold",
    color: "#888",
    marginBottom: 10,
  },
  readOnlyContainer: {
    flexGrow: 1,
    backgroundColor: "lightblue",
    padding: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    marginBottom: 10,
    marginBottom: 20
  }, 
  viewContainerMax: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 600,
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
  },
  viewContainerMax1: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 450,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
  },
  viewContainerMax2: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 350,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewContainerMax3: {
    flex: 1, 
    backgroundColor: '#fff', 
    borderRadius: 20, 
    marginTop: 10,
    minWidth: 300,
    paddingTop: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
});
