import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableHighlight,
  ActivityIndicator,
  Dimensions,
  Image,
  Platform,
  Alert,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
//import SignaturePad from '../../widgets/signaturepad';
import * as FileSystem from "expo-file-system";
import base64 from "base64-js";
import colors from "../../constants/colors";
// import ExpoPixi from "expo-pixi";
import SignatureCanvas from 'react-signature-canvas';
import NewIssue from "../dialogs/newIssue";
import "../styles.css";

export default class Signature extends Component {
  state = {
    disabled: false,
    isSigning: 0,
    signature: this.props.initial?this.props.initial:"",
    show: true,
    signatureEdit: false,
    dialogVisible: false,
    modalVisible: false,
  };
  signatureRef = React.createRef();
  sendSignature(sig) {
    const { id, onChange } = this.props;
    onChange(id, sig);
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({signature: nextProps.initial ? nextProps.initial : ""});
    }
  }

  decodeCredential(input) {
    if (
      input && // if the input exists
      typeof input === "string" && // and it's a string
      input.startsWith("__enc__") === true // and it's encoded yet
    ) {
      const newInput = input.replace("__enc__", ""); // remove the prepending string
      const utf8Bytes = base64.decode(newInput); // base64 decode it
      const output = utf8.decode(utf8Bytes); // utf8 decode it
      return output.replace(SALT, "");
    }    
    
    return input;
  }

  signatureChanged = (paths) => {
    this.setState({ signature: paths, disabled: false });
  };

  _signaturePadError = (error) => {
    console.error(error);
  };

  _signaturePadChange = ({ base64DataUrl }) => {
    this.setState({
      signature2: base64DataUrl,
      signature: base64DataUrl,
      disabled: false,
    });
    this.sendSignature(base64DataUrl);
  };

  clearSignature() {
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }

  onChange = () => {
    for (let line of this.sketch.lines) {
      //console.log(line.points);
    }
  };

  cancelSignature(){
    
    this.signatureRef.current.clear();
    
  }

  getURL(){
    if(this.state.signature != null){
      return this.state.signature.includes('data:image/')&&this.state.signature?this.state.signature:`data:image/png;base64,${this.state.signature}`;
    }
    return this.props.initial;   
  }

  getImageWidth(width){
    switch(width){
      case "small":
        return 150
      case "medium":
        return 250
      case "large":
        return 350
      default:
        return 500
    }
  }
  
  render() {
    const { label, isIssuable, onSubmitIssue, id, isCompleted, placeholder, widthS, openIssue, defaultComponent } = this.props;
    const color = 0x000000;
    const width = 5;
    const alpha = 0.5;
   
    
    return (
      <>
        { !widthS ?
          <NewIssue  
            visible={this.state.dialogVisible} 
            id={id} 
            label={label} 
            onSubmitIssue={onSubmitIssue} 
            handleClose={()=>this.setState({dialogVisible:false})} 
            width={widthS}
          /> : null
        }

      <View style={{ ...styles.container, 
        paddingTop: widthS ? 0 : 5
      }}>
        {
          (label || placeholder) &&
            <Text style={styles.label}>
              {label}
              <Text style={{fontStyle: 'italic',color: colors.primary,fontSize: 20,}}>
                {placeholder}
              </Text>
            </Text>
        }
        
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            if(widthS){
              openIssue();
            }else{
              this.setState({dialogVisible:true});
            }
          }}>
            <Text style={{color: colors.primary, fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }
     
          <View>
            {/* {this.state.signature != "0" && this.state.signature != "" ? (
              <Image
                style={{ height: 200}}
                resizeMethod="resize"
                resizeMode="center"
                source={{
                  uri: this.state.signature.includes('data:image/')?this.state.signature:`data:image/png;base64,${this.state.signature}`,
                }}
              />
            ) : <>
            </> } */}
          <View>

          <View style={{ flexDirection: 'column'}}>
              <View>
                {this.state.signature != "0" && this.state.signature != ""
                  && !this.state.signatureEdit && (
                  <Image
                    style={{ 
                      height: 200,
                      width: this.getImageWidth(widthS),
                    }}
                    resizeMethod="resize"
                    resizeMode="center"
                    source={{
                      uri: this.state.signature.includes('data:image/')?this.state.signature:`data:image/png;base64,${this.state.signature}`,
                    }}
                  />
                ) }

                {
                  this.state.signatureEdit &&
                    <SignatureCanvas ref={this.signatureRef} 
                    minDistance={1}
          
                    onEnd={() => {
                      const imageData = this.signatureRef.current.toDataURL()
                        
                      this.setState({ signature: imageData });
                      this.sendSignature(imageData)
                    }}
                    
                    canvasProps={{
                      className: 'signature'
                    }}/>  
                }
              </View>
          </View>
            <View style={{ flex: 1}}>


              <View style={{ flex: 1}}>
              {!this.state.signatureEdit &&
                <TouchableHighlight
                    style={[styles.btnModal, {backgroundColor: colors.orange}]}
                    onPress={(e) => {    
                      if(!defaultComponent){
                        this.setState({ signatureEdit: true });                    
                      }
                    }}
                  >
                    {
                      this.state.signature === "" ?  <Text style={{fontSize: 14,color: "#fff",}}>Set signature</Text> :
                      <Text style={{fontSize: 14,color: "#fff",}}>Change signature</Text>
                    }
                </TouchableHighlight>
              }
              {this.state.signatureEdit &&
                <TouchableHighlight
                    style={[styles.btnModal, {backgroundColor: colors.orange}]}
                    onPress={(e) => {                  
                      this.setState({ signatureEdit: false });
                    }}
                  >
                    <Text style={{fontSize: 14,color: "#fff",}}>Save</Text>
                </TouchableHighlight>
              }
              {this.state.signatureEdit &&
                  <TouchableHighlight
                    style={[styles.btnModal, {backgroundColor: colors.primary}]}
                    onPress={(e) => {
                      this.sendSignature("");
                      this.setState({ signature: "" });
                      this.clearSignature();
                      this.setState({ signatureEdit: false });
                    }}
                  >
                    <Text style={{fontSize: 14,color: "#fff",}}>Clear signature</Text>
                  </TouchableHighlight>
              }
              </View>

            </View>
          
          </View>
        </View>
        
        
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    paddingTop: 5,
    // paddingHorizontal: 10,
    // paddingBottom: 10,
  },
  signature: {
    backgroundColor: "#fff",
    borderRadius: 5,
    overflow: "hidden",
    marginLeft: 0,
    marginRight: 0,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    marginBottom: 10,
  },
  wrapper: {
    flexDirection: "column",
    alignItems: "center",
  },
  buttonCreate: {
    height: 60,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
    borderRadius: 12,
    borderColor: colors.primary,
    borderWidth: 1,
  },
  buttonText: {
    fontSize: 14,
    color: colors.primary,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  btnModal: {
    padding: 0,
    width: "100%",
    maxWidth: 300,
    height: 58,
    borderRadius: 6,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    marginTop: 25,
  }
});
