import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import colors from "../../constants/colors";
export default class Input extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
      height: 30,
      dialogVisible: false,
    };
  }
  
  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({value: nextProps.initial});
    }
  }

  render() {
    const { value } = this.state;
    
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, width, openIssue, defaultComponent } = this.props;
    return (
      <>
      { !width ?
        <NewIssue  
          visible={this.state.dialogVisible} 
          id={id} 
          label={label} 
          onSubmitIssue={onSubmitIssue} 
          handleClose={()=>{
            this.setState({dialogVisible:false})
          }} 
          width={width}
        /> : null
      }
        

<View style={styles.container, {
  paddingTop: width ? 0 : 5
}}>
          <Text style={styles.label}>{this.props.label}</Text>
          {
            (isIssuable && isCompleted) && 
            <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
              if(width){
                openIssue();
              }else{
                this.setState({dialogVisible:true});
              }
            }}>
              <Text style={{color: colors.primary, fontWeight:'bold'}}>Issue</Text>  
            </TouchableOpacity>
          }
          <TextInput
            value={value}
            editable={ !defaultComponent }
            onContentSizeChange={(event) => {
              if(event.nativeEvent.contentSize.height == 0){
                if(this.props.placeholder == ''){
                  this.setState({height: 30})
                }
                this.setState({height: event.nativeEvent.contentSize.height});
              }else{
                setTimeout(() => {
                  this.setState({height: event.nativeEvent.contentSize.height});
                }, 5)
              }
              }}
              style={styles.input,{ 
              borderColor: "#e7e7e7",
              borderRadius: 5,
              borderWidth: 1,
              color: "#424242",
              fontFamily: "Roboto",
              fontSize: 20,
              fontWeight: "400",
              padding: 5,
              height: this.state.height,
              fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic': 'normal' : 'normal',
              overflow: 'visible',
            }}
              multiline={true}
            placeholder={this.props.placeholder}
            placeholderTextColor={colors.primary}
            onChangeText={(val) => {
              if(val == '' && this.props.placeholder == ''){
                this.setState({ value: val, height: 30 });

              }else{
                this.setState({ value: val });

              }
              onChange(id, val);

            }}
          />
        </View>
      </>

    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    // paddingBottom: 10,
    // paddingHorizontal: 10,
    paddingTop: 5,
    flex: 1,
  },
  input: {
    borderColor: "#e7e7e7",
    borderRadius: 5,
    borderWidth: 1,
    color: "#424242",
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "400",
    paddingLeft: 10,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
});
