import React, { Component } from "react";
import {
    View,
    Platform,
    StyleSheet,
  } from "react-native";
import { connect } from "react-redux";
import Lottie from "lottie-react"

class Animations extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
      }

      componentDidMount() {
      }

    render() {
        let animationSrc;
        if(this.props.animationfile=='loading-dots')
          animationSrc=require('./animations/loading-dots.json');

        if(this.props.animationfile=='loading-dots-gray')
          animationSrc=require('./animations/loading-dots-gray.json');
    
        console.log(animationSrc)
        return (
            <View>
              <Lottie style={styles.loadingAnimation} animationData={animationSrc} autoPlay loop />
            </View>
        );
    }

}
export default connect()(Animations)

const styles = StyleSheet.create({
loadingAnimation: { 
    height: 60,
    width: 60,
    right: 0,     
  },
});