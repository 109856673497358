export default {
  primary: "#4eafa9",
  primary_light: "#20B2AA33",
  secondary: "#4eafa9",
  tertiary: "#4eafa9",
  accent: "#0084BF",
  error: "#DB3A3A",
  gray: "#F8F8F8",
  pink: "#EC407A",
  ambar: "#FBC02D",
  cyan: "#80DEEA",
  yellow: "#fac818",  
  orange: "#4eafa9",
  gray_darken_2: "#CFD8DC",
  gray_darken_3: "#455A64",  
  blue_gray: "#4eafa9",
  success: "#56bf72",
  black: "#000",  
  title: "#000000",
  subtitle: "#a7aaaa",
  description: "#a7aaaa",
  disabled: "#a7aaaa",
  userCommand: "#4eafa9",
  userCommandText: "#ffffff",
  commandSecondary: "#CFD8DC",
  commandSecondayText: "#4eafa9",
};
